import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import {
  DEFAULT_TABLE_OPTIONS,
  SORT_ORDER_DESC,
  TableOptions
} from "@/api/request";
import { SearchRequest, DeleteRequest } from "@/api/account-role/request";
import { SearchItem } from "@/api/account-role/response";
import AccountRoleSearch from "@/store/account-role/search";
import RoleShopGet from "@/store/role-shop/get";
import ShopRoleGet from "@/store/shop-role/get";
import AccountGet from "@/store/account/get";
import AccountRoleModify from "@/store/account-role/modify";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIDialogDelete,
    UIFormRow
  }
})
export default class AuthoritySetting extends Vue {
  @Prop({ type: String, required: true })
  id!: string;

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  staticHeaders = [
    { text: "ログインID", value: "loginId", sortable: false },
    { text: "管理者名", value: "name", sortable: false },
    { text: "メールアドレス", value: "email", width: "103", sortable: false },
    {
      text: "アカウント有効期限",
      value: "expireDate",
      width: "103",
      sortable: false
    },
    {
      text: "パスワード有効期限",
      value: "expirationPasswordDate",
      width: "103",
      sortable: false
    },
    {
      text: "状態",
      value: "status",
      sortable: false
    }
  ];

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "店舗アカウント設定";
  headingSub = "Login members setting";
  breadCrumbs = [
    { text: "設定", disabled: true },
    {
      text: "店舗アカウント設定一覧",
      disabled: false,
      to: { name: "login-members" }
    },
    { text: "権限設定", disabled: true }
  ];

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "shopId" },
    { text: "店舗名", value: "shopName" },
    { text: "権限ロール名", value: "roleName" },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };
  // ------------

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除されるタイトル
  deletingTitle = "";

  // 削除されるメッセージID
  deletingId = 0 as number;

  showRoleDialog = false;

  accountRole = {
    id: null as number | null,
    shopId: null as number | null,
    roleId: null as number | null
  };

  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    id: null as number | null,
    shopId: null as number | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {
    page: 1,
    displayCount: 20,
    sortKey: "shop_id",
    sortOrder: SORT_ORDER_DESC
  };

  // ------------

  /**
   * 新規作成権限の有無
   */
  get writeFlg() {
    return AccountRoleSearch.getWriteFlg;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return AccountRoleSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return AccountRoleSearch.getTotalCount;
  }

  get items() {
    return AccountGet.getItems;
  }

  get roleShopItems() {
    return RoleShopGet.getItems;
  }

  get shopRoleItems() {
    return ShopRoleGet.getItems;
  }

  @Watch("accountRole.shopId")
  async watchRoleShopId() {
    if (this.accountRole.shopId) {
      this.accountRole.roleId = null;
      await ShopRoleGet.get({ id: this.accountRole.shopId });
    } else {
      this.accountRole.roleId = null;
      await ShopRoleGet.clearResponse();
    }
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await AccountGet.get({ id: Number(this.id) });
    await RoleShopGet.get();
    await AccountRoleSearch.restore(this.inputOptions as SearchRequest);
    const request = AccountRoleSearch.getSearchRequest;
    // 検索入力オプション
    request.shopId && (this.inputOptions.shopId = request.shopId);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await AccountRoleSearch.clearResponse();
    await ShopRoleGet.clearResponse();
    await RoleShopGet.clearResponse();
    await AccountGet.clearResponse();
    await AccountRoleModify.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  editClickCallback(item: SearchItem) {
    this.showRoleDialog = true;
    this.accountRole.id = item.id;
    this.accountRole.shopId = null;
    this.accountRole.roleId = null;
  }

  newClickCallback() {
    this.showRoleDialog = true;
    this.accountRole.id = null;
    this.accountRole.shopId = null;
    this.accountRole.roleId = null;
  }

  async saveAccountRole() {
    if (!this.accountRole.roleId) {
      return;
    }
    this.isLoading = true;
    await AccountRoleModify.register({
      id: this.accountRole.id,
      accountId: this.items[0].id,
      roleId: this.accountRole.roleId
    });
    if (AccountRoleModify.isSuccess) {
      this.showRoleDialog = false;
      await Flash.setSuccessNow({
        message: "店舗アカウント権限を設定しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: AccountRoleModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: SearchItem) {
    this.showDialog = true;
    this.deletingId = item.id;
    this.deletingTitle = `${item.shopName}：${item.roleName}`;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (this.deletingId <= 0) {
      return;
    }

    this.isLoading = true;
    await AccountRoleModify.deleteOne({
      id: this.deletingId
    } as DeleteRequest);
    if (AccountRoleModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "店舗アカウントを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: AccountRoleModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  back() {
    this.$router.back();
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let request = this.createRequest();
    await AccountRoleSearch.search(request);
    if (!AccountRoleSearch.isSuccess) {
      await Flash.setErrorNow({
        message: AccountRoleSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  goToNew() {}

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request: SearchRequest = this.tableOptions as SearchRequest;
    if (this.inputOptions.shopId) {
      request.shopId = this.inputOptions.shopId;
    } else {
      delete request.shopId;
    }
    request.id = Number(this.id);
    return request;
  }
}
