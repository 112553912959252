import service from "@/api/service";
import {
  GetRequest,
  RegisterRequest,
  SearchRequest
} from "@/api/account/request";
import {
  GetAccountAgreementResponse, GetAccountAllShopResponse,
  GetResponse,
  RegisterResponse,
  SearchResponse
} from "@/api/account/response";
import { DeleteRequest } from "@/api/account/request";
import { CommonResponse } from "@/api/response";
import {
  GetAccountShopRequest,
  GetAccountRoleShopRequest
} from "@/api/account/request";
import {
  GetAccountShopResponse,
  GetAccountRoleShopResponse
} from "@/api/account/response";

/**
 * 店舗アカウント設定一覧検索APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-account", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 店舗アカウント設定取得APIをコールします。
 *
 * @param getRequest 取得のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-account", getRequest);
  return response.data as GetResponse;
}

/**
 * 店舗アカウント登録更新APIをコールします。
 *
 * @param registerRequest
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-account", registerRequest);
  return response.data as RegisterResponse;
}

/**
 * 店舗アカウント設定削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-account", deleteRequest);
  return response.data as CommonResponse;
}

/**
 * 店舗アカウント関連店舗情報取得APIをコールします。
 *
 * @param getAccountShopRequest 検索のリクエストボディ
 * @return GetAccountShopResponse
 */
export async function getAccountShop(getAccountShopRequest: GetAccountShopRequest) {
  const response = await service.post("get-account-shop", getAccountShopRequest);
  return response.data as GetAccountShopResponse;
}

/**
 * 権限ロールの店舗情報取得APIをコールします。
 *
 * @param getAccountRoleShopRequest 検索のリクエストボディ
 * @return GetAccountRoleShopResponse
 */
export async function getAccountRoleShop(getAccountRoleShopRequest: GetAccountRoleShopRequest) {
  const response = await service.post("get-account-role-shop", getAccountRoleShopRequest);
  return response.data as GetAccountRoleShopResponse;
}

/**
 * ログインユーザー所属店舗契約取得APIをコールします。
 *
 * @return GetAccountAgreementResponse
 */
export async function getAccountAgreement() {
  const response = await service.post("/account-agreement");
  return response.data as GetAccountAgreementResponse;
}

/**
 * ログインユーザー所属店舗一覧取得APIをコールします。
 *
 * @return GetAccountAllShopResponse
 */
export async function getAccountAllShop() {
  const response = await service.post("/account-all-shop");
  return response.data as GetAccountAllShopResponse;
}