import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as AccountRoleAPI from "@/api/account-role";
import { CommonResponse, isSuccess } from "@/api/response";
import { DeleteRequest, RegisterRequest } from "@/api/account-role/request";

const MODULE_NAME = "account-role/modify";

/**
 * 店舗アカウント権限設定削除/登録更新APIのレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Modify extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.commonResponse);
  }

  get getMessage() {
    return this.commonResponse.message;
  }

  // MutationActions
  @MutationAction
  async register(registerRequest: RegisterRequest) {
    const commonResponse = await AccountRoleAPI.register(registerRequest);
    return {
      commonResponse
    };
  }

  // MutationActions
  @MutationAction
  async deleteOne(deleteRequest: DeleteRequest) {
    const commonResponse = await AccountRoleAPI.deleteOne(deleteRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
}

export default getModule(Modify);
