import service from "@/api/service";
import { RegisterRequest, SearchRequest } from "@/api/account-role/request";
import { SearchResponse } from "@/api/account-role/response";
import { DeleteRequest } from "@/api/account-role/request";
import { CommonResponse } from "@/api/response";

/**
 * 店舗アカウント権限設定一覧検索APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/get-account-role", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 店舗アカウント権限設定登録更新APIをコールします。
 *
 * @param registerRequest
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-account-role", registerRequest);
  return response.data as CommonResponse;
}

/**
 * 店舗アカウント権限設定削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-account-role", deleteRequest);
  return response.data as CommonResponse;
}
